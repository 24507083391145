import {css} from 'lit';
import {customElement} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {BaseAccordionItemElement} from './base-accordion-item';

@customElement('clickable-item')
export class ClickableItemElement extends BaseAccordionItemElement {
  static styles = [
    BaseAccordionItemElement.baseStyles,
    css`
      :host {
        box-shadow: initial;
        margin-bottom: 15px;
      }
      .accordion-header {
        border: initial;
        margin-bottom: 0;
        position: relative;
      }
      .accordion-content {
        border-bottom: 1px solid #ddd;
      }
      .collapsed .collapse {
        border-bottom: initial;
      }
      .item-title {
        font-size: 1rem;
      }
      .title-link {
        color: var(--link-color);
        line-height: 1.2rem;
        padding: 0.2rem 1.25rem;
      }
      .body {
        padding: 0.2rem 1.25rem;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'clickable-item': ClickableItemElement;
  }
}
