import {html} from 'lit';

const _printEstimate = (estimate: number) => {
  const minutes = estimate;
  const hours = String(Math.floor(estimate / 60));
  const rest_minutes = String(estimate % 60);

  return minutes > 60
    ? `${hours}:${rest_minutes.padStart(2, '0')} uur`
    : `${minutes} min`;
};

export const estimateSpan = (estimate: number) => html`
  <span class="time-estimate">
    <md-icon class="float-icon">schedule</md-icon>
    ${_printEstimate(estimate)}
  </span>
`;
