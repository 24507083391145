interface EstimateChangeEventDetail {
  estimate: number;
  progress: number;
}
export type EstimateChangeEvent = CustomEvent<EstimateChangeEventDetail>;

export const EstimateChangeEventName = 'estimate-change';

declare global {
  interface WindowEventMap {
    [EstimateChangeEventName]: EstimateChangeEvent;
  }
}
