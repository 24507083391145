import '@material/web/icon/icon';
import '@material/web/progress/linear-progress';

import {LitElement, html, css, unsafeCSS} from 'lit';
import {customElement, property} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import timeEstimateStyles from './styles/time-estimate.scss?inline';
import {EstimateChangeEventName, type EstimateChangeEvent} from './utils/estimateChangeEvent';
import {estimateSpan} from './utils/timeEstimate';

@customElement('time-estimate')
export class TimeEstimateElement extends LitElement {
  @property({type: Number})
  public accessor estimate = 0;

  @property({type: Number})
  public accessor progress = 0;

  private get _progressValue() {
    return Math.min(Math.max(this.progress, 0), 100) / 100;
  }

  static styles = [
    css`${unsafeCSS(timeEstimateStyles)}`,
    css`
      :host {
        --md-linear-progress-track-shape: 8px;
      }
      .estimate-block {
        line-height: 24px;
        margin: 0;
        width: 6rem; // enough to fit 4 numbers
      }
      .progress {
        margin: 5px auto;
        width: 90%;
      }
    `,
  ];

  render() {
    return html`
      <div class="estimate-block">
        ${estimateSpan(this.estimate)}
        <md-linear-progress class="progress" value="${this._progressValue}"></md-linear-progress>
      </div>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(EstimateChangeEventName, this._estimateChange);
  }

  disconnectedCallback() {
    window.removeEventListener(EstimateChangeEventName, this._estimateChange);
    super.disconnectedCallback();
  }

  private _estimateChange = (event: EstimateChangeEvent) => {
    this.estimate = event.detail.estimate;
    this.progress = event.detail.progress;
  };
}

declare global {
  interface HTMLElementTagNameMap {
    'time-estimate': TimeEstimateElement;
  }
}
