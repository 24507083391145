import '@material/web/select/filled-select';
import '@material/web/select/select-option';

import {LitElement, html, css, type TemplateResult, type PropertyValues} from 'lit';
import {customElement, query, queryAssignedElements, state} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {type AnimatablePageElement} from './animatable-page';

type SelectElement = HTMLElementTagNameMap['md-filled-select'];

@customElement('selector-content')
export class SelectorContentElement extends LitElement {
  @state()
  public selected = 0;

  @state()
  private selectOptions: Array<TemplateResult> = [];

  @query('.select-element')
  private accessor selectElement: SelectElement | null = null;

  @queryAssignedElements({selector: 'animatable-page'})
  private accessor selectableElements: Iterable<AnimatablePageElement> = [];

  static styles = css`
    :host {
      display: block;
    }

    .select-element {
      --md-sys-color-primary: var(--selector-content-base-color);
      --md-sys-color-surface-container-highest: var(--selector-content-background-color);
      --md-sys-color-surface-container: var(--selector-content-item-color);
      --md-sys-color-secondary-container: var(--selector-content-selected-color);

      display: block;
      margin: 0 auto;
      width: 17rem;
    }

    ::slotted(animatable-page:not(.selected)) {
      display: none !important;
    }
  `;

  render() {
    return html`
      <div class="selector-wrapper">
        <md-filled-select class="select-element" value="${this.selected}" @input="${this._onSelect}">
          ${this.selectOptions}
        </md-filled-select>
        <slot @slotchange="${this._handleSlotchange}"></slot>
      </div>
    `;
  }

  shouldUpdate(changedProperties: PropertyValues<this>) {
    super.shouldUpdate(changedProperties);

    if (changedProperties.has('selected')) {
      this._setPageSelectedAttributes();
    }

    return true;
  }

  private _selectOptionsContent() {
    return [...this.selectableElements].map((animatablePage, index) => html`
      <md-select-option value="${index}" ?disabled="${animatablePage.disabled}">
        <div slot="headline">${animatablePage.title}</div>
      </md-select-option>
    `);
  }

  private _handleSlotchange() {
    this.selectOptions = this._selectOptionsContent();
    this._setPageSelectedAttributes();
  }

  private _onSelect() {
    const value = Number(this.selectElement?.value);
    this.selected = !Number.isNaN(value) ? value : this.selected;
  }

  private _setPageSelectedAttributes() {
    [...this.selectableElements].forEach((animatablePage, index) =>
      animatablePage.classList.toggle('selected', index === this.selected));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'selector-content': SelectorContentElement;
  }
}
